import { handleGaTracking } from "../../../../../../utils/tracking"

class MercureLoyalty extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "loyalty"

  constructor(componentHost: HTMLElement) {
    super(componentHost)

    handleGaTracking({
      node: componentHost.querySelector(".ace-loyalty__button a"),
      htmlEventType: CoreJS.DomEventConstants.CLICK,
      eventName: "cta_enroll",
      additionalData: {
        enroll_context: "loyalty",
        cta_name: "become member",
      },
    })
  }
}

CoreJS.BaseComponent.registerComponent(MercureLoyalty.CLASS_NAMESPACE, MercureLoyalty, true)
