import { handleGaTracking } from "../../../../../../utils/tracking"

class MercureSlideshowCarousel extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "slideshow-carousel"

  constructor(componentHost: HTMLElement) {
    super(componentHost)

    const actionButtons: NodeListOf<HTMLButtonElement> = componentHost.querySelectorAll(".cmp-carouselslideshow__action")
    const titleElement: HTMLElement | null = componentHost.querySelector("[class^='ace-highlight__content--title']")


    actionButtons.forEach((actionButton) => {

      handleGaTracking({
        node: actionButton,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: titleElement?.innerText.trim().toLowerCase(),
          bloc_interaction: "see photos",
        },
      })

    })

  }
}

CoreJS.BaseComponent.registerComponent(MercureSlideshowCarousel.CLASS_NAMESPACE, MercureSlideshowCarousel, true)
