import { TagManager } from "@accor/ace-ui-core"

const getDataLayer = (): Record<string, string> => {
  const dataLayerAttr = "data-cmp-datalayer"

  const dataLayer = document?.querySelector(`[${dataLayerAttr}]`)?.getAttribute(dataLayerAttr)

  return dataLayer ? JSON.parse(dataLayer) : {}
}

interface EventTrackingInputs {
  node: Node | null
  htmlEventType: string
  eventName: string
  additionalData?: Record<string, any>
}

const handleGaTracking = ({ node, htmlEventType, eventName, additionalData }: EventTrackingInputs) => {
  const dataLayer = getDataLayer()
  if (dataLayer && node) {
    node.addEventListener(htmlEventType, () => {
      const eventData = {
        event: "GA4event",
        eventName,
        event_data: {
          pagename: dataLayer.pageName,
          ...additionalData,
        },
      }

      TagManager.trackEvent(eventData)
    })
  }
}

const currentComponentIsNextTo = (currentComponent: HTMLElement, brotherClass: string) =>
  currentComponent.previousElementSibling?.classList.contains(brotherClass) || false

const currentComponentIsChildOf = (currentComponent: HTMLElement, parentClass: string) : boolean  => currentComponent.closest(`.${parentClass}`) ? true : false

export { getDataLayer, handleGaTracking, currentComponentIsNextTo, currentComponentIsChildOf }
