import { handleGaTracking } from "../../../../../../utils/tracking"

class MercureSocialMedia extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "social-media"

  constructor(componentHost: HTMLElement) {
    super(componentHost)

    handleGaTracking({
      node: componentHost.querySelector(".cmp-socialMedia_cta a"),
      htmlEventType: CoreJS.DomEventConstants.CLICK,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "instagram",
        bloc_interaction: "follow on instagram",
      },
    })
  }
}

CoreJS.BaseComponent.registerComponent(MercureSocialMedia.CLASS_NAMESPACE, MercureSocialMedia, true)
