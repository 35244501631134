import { handleGaTracking } from "../../../../../../utils/tracking"

class MercureFaq extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "faq"

  constructor(componentHost: HTMLElement) {
    super(componentHost)

    const accordionButtons: NodeListOf<HTMLElement> = componentHost.querySelectorAll(".cmp-accordion__button")
    const moreButton: Node | null = componentHost.querySelector(".cmp-accordion__more-button")

    accordionButtons.forEach(button => {

      handleGaTracking({
        node: button,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "faq",
          bloc_interaction: button.innerText.trim().toLowerCase(),
        },
      })

    })

    moreButton && handleGaTracking({
      node: moreButton,
      htmlEventType: CoreJS.DomEventConstants.CLICK,
      eventName: "bloc_interact",
      additionalData: {
        bloc_name: "faq",
        bloc_interaction: "see all questions",
      },
    })

  }
}

CoreJS.BaseComponent.registerComponent(MercureFaq.CLASS_NAMESPACE, MercureFaq, true)
