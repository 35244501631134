import { handleGaTracking } from "../../../../../../utils/tracking"

class MercureNavigationHeader extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "ace-header-navigation__wrapper"

  constructor(componentHost: HTMLElement) {
    super(componentHost)
    

    // Menu items to navigate
    const navItems: NodeListOf<HTMLLIElement> = componentHost.querySelectorAll("ul.link-navigation_nav_menu-list li.link-navigation__menuitem:not(.--hidden), .link-navigation__sub-more-container .link-navigation__nav__item:not(--hidden)")
    // Menu items to change geolocation and currency
    const navItemsExtra: NodeListOf<HTMLButtonElement> = componentHost.querySelectorAll(".ace-header-navigation__pos-container > * > button")

    // Burger icon to open menu on mobile
    const burgerButton = componentHost.querySelector("button.ace-burger-menu")


    navItems.forEach((navItem, navItemIndex) => {

      if( navItem.closest(".link-navigation__morePanel") ) {
        navItem.closest(".link-navigation__morePanel")?.addEventListener(CoreJS.DomEventConstants.CLICK, event => {
          event.stopPropagation()
        })
      }

      handleGaTracking({
        node: navItem,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: "menu_interact",
        additionalData: {
          menu_position: (navItemIndex + 1).toString(),
          menu_label: navItem.innerText?.trim().toLowerCase()
        }
      })
  
    })

    
    type LabelNavItemExtra = "geolocation" | "currency" | undefined

    navItemsExtra.forEach((navItemOther, index) => {
      const labelCatched = navItemOther.parentElement?.classList[0].replace(/(ace-|-selector)/g, "") as LabelNavItemExtra
      let labelToSend

      switch (labelCatched) {
        case "geolocation":
          labelToSend = "select country"
          break
        case "currency":
          labelToSend = "select currency"
          break
      }

      handleGaTracking({
        node: navItemOther,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: "menu_interact_other",
        additionalData: {
          menu_position: navItems.length + index + 1,
          menu_label: labelToSend,
        },
      })
    })

    
    burgerButton && handleGaTracking({
      node: burgerButton,
      htmlEventType: CoreJS.DomEventConstants.CLICK,
      eventName: "menu_open"
    })

  }

}

CoreJS.BaseComponent.registerComponent(MercureNavigationHeader.CLASS_NAMESPACE, MercureNavigationHeader, true)
